import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/nl';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// utils
import './utils/lang';

// constants
import packageJson from '../package.json';

// Moment default settings
moment.defaultFormat = 'DD-MM-YYYY';
// TODO: check if config is needed
moment.updateLocale(window.client.locale || 'nl', {
  week: {
    dow: 1,
  },
  weekdays: 'zondag_maandag_dinsdag_woensdag_donderdag_vrijdag_zaterdag'.split('_'),
  longDateFormat: {
    LT: 'HH:mm',
    L: 'DD-MM-YYYY',
  },
  calendar: {
    lastDay: '[Gisteren om] LT',
    sameDay: '[Vandaag om] LT',
    nextDay: '[Morgen om] LT',
    lastWeek: '[Afgelopen] dddd [om] LT',
    nextWeek: 'dddd [om] LT',
    sameElse: 'L',
  },
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '%ds',
    ss: '%ds',
    m: '%dmin',
    mm: '%dmin',
    h: '%du',
    hh: '%du',
    d: '%dd',
    dd: '%dd',
    w: '%dw',
    ww: '%dw',
    M: '%dm',
    MM: '%dm',
    y: '%dj',
    yy: '%dj',
  },
});

if (process.env.REACT_APP_ENVIRONMENT) {
  Sentry.init({
    dsn: 'https://72493de3b4474f83b2e210839fd913e2@o909283.ingest.sentry.io/5844560',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENVIRONMENT || 'unknown',
    release: `my-journey@${packageJson.version}`,
    tracesSampleRate: 0.2,
  });
}

/**
 * Start app by fetch client information first
 */
(async () => {
  if (process.env.REACT_APP_BUILD === 'superadmin') {
    const Router = React.lazy(() => import('./RouterSuper'));
    window.client = { ...window.client, primaryColor: '#283a5b' };
    window.Lang.setLocale(window.client.locale);

    ReactDOM.render(
      <React.StrictMode>
        <React.Suspense fallback={null}>
          <Router />
        </React.Suspense>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  } else {
    const Router = React.lazy(() => import('./Router'));

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/theme`);
      window.client = { ...window.client, ...data };
      window.Lang.setLocale(window.client.locale);
    } catch (error) {
      ReactDOM.render(<>No theme found for this client.</>, document.body);
      return;
    }

    ReactDOM.render(
      <React.StrictMode>
        <React.Suspense fallback={null}>
          <Router />
        </React.Suspense>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  }
})();
